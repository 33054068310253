.transactions-table {
	.table-wrapper {
		display: block;
		overflow: auto;
		width: 100%;
	}

	.mat-sort-header-container {
		justify-content: inherit !important;
	}
	.mat-column-description {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 200px;
		padding-right: 12px;
	}
	td.mat-column-overlapWarning {
		mat-chip.warning-counter-badge {
			background: #FFB965;
			font-size: 16px;
		}
	}
	.mat-column-singleTagColumn {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 300px;
	}
}


.mat-column-status {
	mat-chip.mat-standard-chip {
		padding: 1px 10px !important;
		min-height: 24px;

		&:hover::after,
		&:focus::after {
			opacity: 0 !important;
		}

		&:not(.mat-chip-disabled):active {
			box-shadow: none !important;
		}
	}

	mat-chip.pending,
	mat-chip.posted {
		font-size: 14px;
		&:hover {
			opacity: 1;
		}
	}

	mat-chip.pending {
		color: #a95c00;
		background: #ffeed8;
	}

	mat-chip.posted {
		color: #00813c;
		background: #def7e4;
	}
}

app-transactions-table {
    .mat-mdc-chip.mat-mdc-standard-chip {
        --mdc-chip-container-height: 24px;
    }
}


trovata-paginated-transactions-table {
	.buttons-separator {
		margin-top: -3px;
		margin-right: 5px;
		margin-left: 20px;
		height: 25px;
	}

	.table-header-total {
		display: flex;
	}

	app-paginated-table {
		mat-chip-row.mat-mdc-chip.mat-mdc-standard-chip.chip-warning {
			background-color: #FFEED8;

			mat-icon.warning-icon {
				color: #9A5300;
				font-size: 16px;
				display: flex;
				align-items: center;
			}

			span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
				display: flex;
				align-items: center;
				color: #9A5300 !important;
			}

			.warning-color-text {
				color: #9A5300;
			}
		}
	}
}
